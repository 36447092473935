// extracted by mini-css-extract-plugin
export var blog = "style-module--blog--vM0xN";
export var blog__head = "style-module--blog__head--334O1";
export var title = "style-module--title--2sfSP";
export var categories = "style-module--categories--dQNCb";
export var search = "style-module--search--1mh6M";
export var blog__body = "style-module--blog__body---NIFZ";
export var featured = "style-module--featured--3xRE-";
export var featured__image = "style-module--featured__image--2VPo8";
export var image = "style-module--image--1TJq-";
export var featured__content = "style-module--featured__content--3ezmI";
export var author = "style-module--author---KzDu";
export var excerpt = "style-module--excerpt--1L5R_";
export var links = "style-module--links--_JTTh";
export var share = "style-module--share--1f02C";
export var posts = "style-module--posts--3l6Fn";
export var post = "style-module--post--1NcDb";
export var roundedBox = "style-module--roundedBox--3a4UJ";
export var pagination = "style-module--pagination--2PMiC";
export var searchWrapper = "style-module--search-wrapper--xhkLb";
export var searchWrapperActive = "style-module--search-wrapper--active--2f6Yo";
import React from "react"
import { graphql, Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import moment from "moment"
import { Layout, Seo, Container, PostCard, Author, AdsCarousel, RoundedBox, DropdownShare, Theme } from "../../components"
import { Search, Share, ArrowRight } from '../../assets/vectors'
import * as styles from './style.module.scss'


const BlogTemplate = ({ data, pageContext }) => {
  const { page, posts, allPosts, categories, play } = data

	const searchBar = React.useRef()

  const [searchTerm, setSearchTerm] = React.useState("")
  const [searchResults, setSearchResults] = React.useState([])
  const [searchIsActive, setSearchIsActive] = React.useState(false)

	const URL_PREFIX = pageContext.lang === "en_us" ? "" : "pt"
	const READ_MORE = pageContext.lang === "en_us" ? "read more" : "leia mais"

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = `/${URL_PREFIX}/blog/${currentPage - 1 === 1 ? "" : "page=" + (currentPage - 1).toString()}`
  const nextPage = `/${URL_PREFIX}/blog/page=${(currentPage + 1).toString()}`

  const handleSearchChange = event => {
    setSearchTerm(event.currentTarget.value)
  }

  const hero = page.blogPageFields.sectionHero.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

  const playFields = play.homePageFields?.sectionPlay.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

  const filteredPosts = posts.edges.filter(post =>
    post.node.categories.nodes.map(node => node.databaseId).includes(10)
  )

  const normalizedPosts = filteredPosts.map(post => ({
    categories: post.node.categories.nodes.filter(
      cat => ![9, 10].includes(cat.databaseId)
    ),
    author: post.node.blogFields.wannaAuthor?.postAuthorFields,
    blog: post.node.blogFields.blogFields?.find(
      variant =>
        variant.languageValue && variant.languageValue.slug === pageContext.lang
    ),
    featured: post.node.blogFields.isFeatured,
    date: moment(post.date).format("ll"),
    slug: post.node.slug,
    id: post.node.databaseId,
  }))

  const featuredPost = normalizedPosts[0]
  const nonFeaturedPosts = normalizedPosts.splice(1)

  React.useEffect(() => {
    const results = allPosts.edges.filter(
      ({node}) => {
				const post = node.blogFields.blogFields[0]
				return post?.blogTitle.toLowerCase().includes(searchTerm.toLowerCase()) |
        			 post?.excerpt.toLowerCase().includes(searchTerm.toLowerCase()) |
        			 node.categories.nodes
          			.map(cat => cat.name.toLowerCase())[0]
          			.includes(searchTerm.toLowerCase())
			}
    )
		
		const filteredData = results.map(({node}) => {
			const obj = {
				categories: node.categories.nodes,
				author: node.blogFields.wannaAuthor.postAuthorFields,
				blog: node.blogFields.blogFields[0],

			}
			
			return obj
		})

    setSearchResults(filteredData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  React.useEffect(() => {
    if (pageContext.lang === "en_us") navigate("/")
  }, [pageContext.lang])

  return (
    <Layout>
      <Seo title={hero.title} />

			<section className={styles.blog}>
				<RoundedBox
					className={styles.roundedBox}
					transform="rotate(26deg)"
					width="916px"
					height="996px"
					position={{
						top: "-180px",
						left: "-230px"
					}}
				/>

				<Container>
          {searchIsActive === false ? (
            <>
              <div className={`${styles.blog__head} flex align-center`}>
    						<h1 className={styles.title}>Blog</h1>
    						{categories?.nodes && categories?.nodes.length > 0 &&
    							<ul className={styles.categories}>
    								{
    									categories.nodes.map(category =>
    										<li key={category.databaseId}><Link className="pill" to={`/${URL_PREFIX}/blog/${category.slug}/`}>{category.name}</Link></li>
    									)
    								}
    							</ul>}
    						<a className={styles.search} onClick={() => (setSearchIsActive(!searchIsActive),
                    setSearchTerm(""), setTimeout(() => (searchBar.current.focus()), 0))}>
    							<Search />
    						</a>
    					</div>
    					<div className={styles.blog__body}>
    						<article className={`${styles.featured} flex`}>
    							<Link to={`/${URL_PREFIX}/blog/${featuredPost?.categories[0].slug}/${featuredPost?.slug}`}>
    								<div className={styles.featured__image}>
    									<GatsbyImage
    										className={styles.image}
    										image={featuredPost?.blog?.blogImage.localFile.childImageSharp.gatsbyImageData}
    										alt={featuredPost?.blog?.blogTitle}
    									/>
    								</div>
    							</Link>
    							<div className={styles.featured__content}>
    								<h1>{featuredPost?.blog?.blogTitle}</h1>
    								<Author className={styles.author} post={featuredPost} pageContext={pageContext} />
    								<p className={styles.excerpt}>{featuredPost?.blog?.excerpt}</p>
    								<div className={styles.links}>
                      <Share className={styles.share} />
                      {/*<DropdownShare className="dropdown"
                        link={`/${URL_PREFIX}/blog/${featuredPost.slug}`}
                        lang={pageContext.lang}
                      /> */}
    									<Link to={`/${URL_PREFIX}/blog/${featuredPost?.categories[0].slug}/${featuredPost?.slug}`}>{READ_MORE}<ArrowRight/></Link>
    								</div>
    							</div>
    						</article>
    						<div className={styles.posts}>
    							{nonFeaturedPosts.slice(0, 3).map(post =>
    									post && post.blog && <>
    									<PostCard
    										className={styles.post}
    										post={post}
    										key={post.id}
    										pageContext={pageContext}
    										shouldHave={{
    											author: true,
    											links: true,
    											excerpt: true
    										}}
    									/>
    									<hr />
    								</>)}
    						</div>
    						<AdsCarousel data={play} />
        				<div className={styles.posts}>
        					{nonFeaturedPosts.splice(3).map(post =>
                  	post && post.blog && <>
                    <PostCard
        							className={styles.post}
        							post={post}
        							key={post.id}
        							pageContext={pageContext}
        							shouldHave={{
        								author: true,
        								links: true,
        								excerpt: true
        							}}
        						/>
        						<hr />
                	</>)}
        				</div>

								<div className={styles.pagination}>
									{
										!isFirst && (
											<Link to={prevPage} rel="prev">
												<span>←</span>
											</Link>
										)
									}

									<span>{pageContext.lang === "en_us" ? "Page" : "Página"} {currentPage}</span>
								
									{
										!isLast && (
											<Link to={nextPage} rel="next">
												<span>→</span>
											</Link>
										)
									}
								</div>
    			   </div>
            </>
          ) : null}

					<div className={`${styles.searchWrapper} ${searchIsActive ? styles.searchWrapperActive : ''}`}>
						{searchIsActive === true ? (
							<Theme.Search>
								{searchTerm !== "" ? (
									<h5>
										{pageContext.lang === "pt_br" ? "Digite sua busca" : "Type your search"}
									</h5>
								): null}
								<div className="search-bar">
									<input
										type="text"
										placeholder={
											pageContext.lang === "pt_br" ? "Digite sua busca" : "Type your search"
										}
										value={searchTerm}
										ref={searchBar}
										className={styles.searchInput}
										onChange={handleSearchChange}
									/>
									<div className="closer"
										onClick={() => setSearchIsActive(!searchIsActive)}>
									</div>
								</div>
							</Theme.Search>
						) : null}
						{searchIsActive === true && searchTerm !== "" ? (
							<div className={styles.blog__body}>
								{searchResults.length > 0 ?
									<div className={styles.posts}>
										{searchResults.slice(0, 3).map(post =>
											post && post.blog && <>
												<PostCard
													className={styles.post}
													post={post}
													key={post.id}
													pageContext={pageContext}
													shouldHave={{
														author: true,
														links: true,
														excerpt: true
													}}
												/>
												<hr />
											</>
										)}
									</div>
								:
								pageContext.lang === "pt_br"
									? "0 Resultados..."
									: "0 Results..."}
								<AdsCarousel data={play} />
								{searchResults.length > 0 ?
									<div className={styles.posts}>
										{searchResults.splice(3).map(post =>
											post && post.blog && <>
												<PostCard
													className={styles.post}
													post={post}
													key={post.id}
													pageContext={pageContext}
													shouldHave={{
														author: true,
														links: true,
														excerpt: true
													}}
												/>
												<hr />
											</>
										)}
									</div>
								: null}
							</div>
						) : null}
					</div>
				</Container>
			</section>
    </Layout>
  )
}

export const Query = graphql`
  query BlogTemplate($limit: Int!, $skip: Int!) {
    page: wpPage(slug: { eq: "blog" }) {
      databaseId
      blogPageFields {
        sectionHero {
          languageValue {
            slug
            name
          }
          title
          subtitle
          bannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
		allPosts: allWpPost(
      filter: {
        status: {eq: "publish"},
        blogFields: {
          blogFields: {
            elemMatch: {
              blogTitle: {ne: null},
              blogImage: {
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {ne: "null"}
                  }
                }
              },
              excerpt: {ne: null},
              languageValue: {
                slug: {ne: null}
              },
              readingTime: {ne: null}
            }
          }
        }
      }
    ) {
      edges {
        node {
          categories {
            nodes {
              databaseId
              slug
              name
              parentDatabaseId
            }
          }
          date
          slug
          databaseId
          blogFields {
            isFeatured
            wannaAuthor {
              ... on WpAuthor {
                postAuthorFields {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 80, layout: FIXED)
                      }
                    }
                  }
                  link
                  name
                }
              }
            }
            blogFields {
              blogTitle
              blogImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000, layout: FIXED)
                  }
                }
              }
              excerpt
              languageValue {
                slug
              }
              readingTime
            }
          }
        }
      }
    }
    posts: allWpPost(
      limit: $limit,
      skip: $skip,
      filter: {
        status: {eq: "publish"},
        blogFields: {
          blogFields: {
            elemMatch: {
              blogTitle: {ne: null},
              blogImage: {
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {ne: "null"}
                  }
                }
              },
              excerpt: {ne: null},
              languageValue: {
                slug: {ne: null}
              },
              readingTime: {ne: null}
            }
          }
        }
      }
    ) {
      edges {
        node {
          categories {
            nodes {
              databaseId
              slug
              name
              parentDatabaseId
            }
          }
          date
          slug
          databaseId
          blogFields {
            isFeatured
            wannaAuthor {
              ... on WpAuthor {
                postAuthorFields {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 80, layout: FIXED)
                      }
                    }
                  }
                  link
                  name
                }
              }
            }
            blogFields {
              blogTitle
              blogImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1000, layout: FIXED)
                  }
                }
              }
              excerpt
              languageValue {
                slug
              }
              readingTime
            }
          }
        }
      }
    }
    categories: allWpCategory(
      filter: {
        count: { gt: 0 }
        databaseId: { nin: [9, 10] }
        parentDatabaseId: { nin: [21] }
      }
    ) {
      nodes {
        slug
        name
        databaseId
        wpChildren {
          nodes {
            databaseId
            name
            count
            slug
          }
        }
      }
    }
    play: wpPage(slug: { eq: "home" }) {
      databaseId
      homePageFields {
        sectionPlay {
          languageValue {
            slug
            name
          }
          banners {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            linkUrl
          }
        }
      }
    }
  }
`


export default BlogTemplate
